import { all, task } from 'ember-concurrency';
import { camelize } from '@ember/string';
import { CUSTOM_MARKETING_CONTENT_KEY, CUSTOM_MARKETING_CONTENT_TYPE, SECTION_TYPES } from '@mvb/tix-ui/constants';
import { isNone, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import ComparativeProductModel from '@mvb/tix-ui/models/comparative-product';
import createChangeset from '@mvb/tix-ui/utils/create-changeset';
import Service, { service } from '@ember/service';

export default class CustomMarketingContentBaseService extends Service {
  @service api;
  @service categoryOfGoods;
  @service errors;
  @service intl;
  @service session;
  @service store;

  /**
   * @typedef LocalChangeset
   * @property {string} guid - either the id of already persisted records or a local guid
   * @property {CustomMarketingContentModel} model - Ember Data record of the customMarketingContent
   * @property {Changeset} modelChangeset - the actual changeset of the model
   *
   * @type {LocalChangeset[]} all customMarketingContents that are currently being displayed to be edited in the UI
   */
  @tracked localChangesets = [];

  /**
   * @type {Changeset[]} changesets of {@link ComparativeProductModel}s
   */
  @tracked comparativeProductChangesets = [];

  maxLengthValidation = {
    max: 10,
    description: this.intl.t('validation.additionalInformation.tooManyCharacters'),
  };

  get categories() {
    return this.categoryOfGoods.current?.categories ?? [];
  }

  get changesetChanges() {
    return this.localChangesets.filter((changeset) => {
      for (let value of Object.values(changeset)) {
        if (value?.changes?.length > 0) {
          return value.changes;
        }
      }
    });
  }

  get comparativeProductChangesetChanges() {
    return this.comparativeProductChangesets.filter((changeset) => {
      if (changeset.data.isNew || changeset.changes.length > 0) {
        return changeset;
      }
    });
  }

  get hasChanges() {
    return this.changesetChanges.length > 0 || this.comparativeProductChangesetChanges.length > 0;
  }

  get hasInvalidData() {
    let hasInvalidChangesets = this.localChangesets.some(({ modelChangeset }) => modelChangeset.isInvalid);
    let hasInvalidComparativeProductChangesets = this.comparativeProductChangesets.some(
      (changeset) => changeset.isInvalid
    );

    return hasInvalidChangesets || hasInvalidComparativeProductChangesets;
  }

  /**
   * checks for changes in the recommendations (marketing or listing or such) and updates them properly for saving
   *
   * @param {*} changeset current changeset of the custom marketing content with all the changes
   * @param {*} model the model data of the custom marketing content before changes were made
   * @param {*} key model name of the recommendation that should be saved/updated
   * @param {*} name (optional) name of the attribute when it differs from the camelized key
   * @returns list (array) of recommendations. Those can be one of the following: listingRecommendations, hugListingRecommendations, marketingRecommendations, branchAmounts
   */
  @task
  @waitFor
  *consolidateRecommendationsTask(changeset, model, key, name) {
    let attributeName = name ?? `${camelize(key)}s`;
    try {
      let recommendationsFromModel = yield model.get(attributeName) ?? [];
      let recommendationsToCreate = [];
      let recommendations = [];

      // get the actual recommendations
      if ([CUSTOM_MARKETING_CONTENT_TYPE.HUGENDUBEL, CUSTOM_MARKETING_CONTENT_TYPE.THALIA].includes(changeset.type)) {
        recommendations = [];
        if (this[attributeName] && changeset[attributeName]) {
          // this[attributeName] holds the data of a codelist or such so we can actually map the required data for saving
          recommendations = this[attributeName]
            .filter((recommendation) => changeset[attributeName].includes(recommendation.code))
            .map((recommendation) => {
              return { value: recommendation.description, code: recommendation.code };
            });
        }
      } else {
        recommendations = isPresent(changeset[attributeName]) ? JSON.parse(changeset[attributeName]) : [];
      }
      // update existing recommendations or add new ones to be created later on
      recommendations = recommendations.map((recommendation, index) => {
        if (recommendation.id) {
          let record = recommendationsFromModel.find((rec) => {
            return rec.id === recommendation.id;
          });
          if (record) {
            record.set('text', recommendation.value);
            record.set('position', index + 1);
          }
          return record;
        } else {
          recommendationsToCreate.push({ ...recommendation, position: index + 1 });
        }
      });

      // create new recommendations
      for (let recommendation of recommendationsToCreate) {
        let record;

        if (attributeName === CUSTOM_MARKETING_CONTENT_KEY.BRANCH_AMOUNTS) {
          //TODO refactor this to retrieve all needed branches in a single call before the for-loop --> #30154
          let branch = yield this.store.findRecord('bookstore-branch', recommendation.branchId);
          record = this.store.createRecord(key, {
            branch,
            recommendedOrderAmount: recommendation.recommendedOrderAmount,
            customMarketingContent: model,
          });
        } else {
          record = this.store.createRecord(key, {
            text: recommendation.value,
            code: recommendation.code,
            position: recommendation.position,
            customMarketingContent: model,
          });
        }

        recommendations.push(record);
      }

      //TODO should be done in chunks --> see ticket #30151
      // check for recommendations that could be removed
      let removedRecommendations =
        recommendationsFromModel
          ?.filter((recommendation) => {
            return !recommendations.includes(recommendation);
          })
          .map((recommendation) => recommendation.destroyRecord()) ?? [];

      yield all(removedRecommendations);
      return recommendations.filter(Boolean);
    } catch (error) {
      this.errors.handle(error);
    }
  }

  /**
   * Saves a {@link LocalChangeset} and its sub-entities and updates related entities with the new relationship
   *
   * @param {LocalChangeset} localChangeset changesetDTO to be saved
   * @param {string} previewId optional previewId if the {@link PreviewModel} has not already been set in the customMarketingContent itself
   */
  @task
  @waitFor
  *saveLocalChangesetTask(localChangeset, { previewId }) {
    try {
      let changeset = localChangeset.modelChangeset;
      let model = localChangeset.model;
      let recordsToSave = [];

      // update records and model for saving
      yield this.updateRecordsAndModelForSavingTask.perform(changeset, model, recordsToSave);

      let noteValue = changeset.get('note');
      noteValue = this.decodeSpecialCharacters(noteValue);
      if (this.containsOnlyOncePAsHtmlTag(noteValue)) {
        noteValue = noteValue.replace('<p>', '');
        noteValue = noteValue.replace('</p>', '');
      }

      // update model (original data) with the actual data from the changeset
      // including fallbacks in case the changeset does not hold those values
      model.set('note', noteValue ?? null);
      model.set('recommendedOrderAmount', changeset.get('recommendedOrderAmount') ?? null);
      model.set('tags', changeset.get('tags') ?? []);
      model.set('files', yield changeset.get('files') ?? []);

      // this part is mostly needed for the saving process triggered by the preview edit page
      let preview;
      if (previewId) {
        preview = this.store.peekRecord('preview', previewId);
        model.set('preview', preview);
      }

      yield model.save();

      // from this point on we don't care about the changeset, but we need to make it seem as if it no longer has changes for things like button activity
      changeset.execute().rollback();

      // update the sectionContent if available as they do not have a direct relationship anymore
      if (preview) {
        let sections = (yield preview.get('sections')) ?? [];
        let section = sections?.find(({ type }) => type === SECTION_TYPES.REFERENCETITLE);
        let sectionContents = (yield section?.get('contents')) ?? [];
        let sectionContent = sectionContents?.find(
          ({ referencedProductId }) => referencedProductId === model.referencedProductId
        );

        sectionContent?.set('customMarketingContent', model);
      }

      let product = this.store.peekRecord('product', model.referencedProductId);
      if (product) {
        product.set('customMarketingContent', model);
      }

      yield this.saveRelatedEntitiesTask.perform(model, recordsToSave);
    } catch (error) {
      if (error.errors?.[0]?.code === 'error.collectionOfGoods.notAllowed') {
        error.messages = error.errors;
        // this just disables the save button, the tree input does not display changeset errors yet
        localChangeset.modelChangeset.pushErrors('assortment', this.intl.t('error.collectionOfGoods.notAllowed'));
      }
      throw error;
    }
  }

  // checks, if '<p>' and '</p>' are both present exactly once and no other HTML tags
  containsOnlyOncePAsHtmlTag(value) {
    let isWrappedInPTags = new RegExp('^<p>.*</p>$').test(value);
    let containsSecondPTag = new RegExp('^<p>.*<p>.*', 'i').test(value);
    let containsOtherTags = new RegExp('</?(?!p\\b)\\w+>', 'i').test(value);
    return isWrappedInPTags && !containsSecondPTag && !containsOtherTags;
  }

  decodeSpecialCharacters(value) {
    if (value !== undefined) {
      let txt = document.createElement('textarea');
      txt.innerHTML = value;
      let extractedValue = txt.value;
      txt.remove();
      return extractedValue;
    }
    return value;
  }

  @task
  @waitFor
  *saveTask({ guid, previewId }) {
    // pre-filtering loop object if guid is set and truthy
    let objects = guid ? this.changesetChanges.filter((changeset) => changeset.id === guid) : this.changesetChanges;

    try {
      for (let localChangeset of objects) {
        yield this.saveLocalChangesetTask.perform(localChangeset, { previewId });
      }
      yield this.saveComparativeProducts.perform(guid);
      return true;
    } catch (error) {
      this.errors.handle(error);
      if (error.errors?.[0]?.code === 'error.collectionOfGoods.notAllowed') {
        return false;
      }
    }
  }
  /**
   * Determines for all the passed in records if they can be deleted or need any other updates before saving.
   *
   * For marketing-recommendations: checks if both code and text are empty and sets the markedForDeletion flag to true if so.
   * We do not need to consider the info field, because we should not be able to save a record with only this value set.
   *
   * @param {*} recordsToSave array of all those relationships that should be saved separately
   * @returns
   */
  cleanupRecordsToSave(recordsToSave) {
    for (let record of recordsToSave) {
      if (['marketing-recommendation'].includes(record.constructor.modelName)) {
        // check if the record should be deleted
        let codeIsEmpty = !isPresent(record.code);
        let textIsEmpty = !isPresent(record.text);
        let statusIsEmpty = !isPresent(record.status);

        if (codeIsEmpty && textIsEmpty && statusIsEmpty) {
          record.set('markedForDeletion', true);
        }
      }
    }

    return recordsToSave;
  }

  getCategoryCode(value) {
    return this.categories.find((cat) => {
      let categoryCode = cat.code;
      if (typeof value === 'number') {
        categoryCode = Number.parseInt(categoryCode, 10);
      }
      return categoryCode === value;
    });
  }

  getChangesetForGuid(guid) {
    return this.localChangesets.find((changeset) => changeset.id === guid);
  }

  unload(guid) {
    for (let changeset of this.localChangesets) {
      if (!guid || changeset.id === guid) {
        changeset.modelChangeset.rollback();
      }
    }
    for (let changeset of this.comparativeProductChangesets) {
      if (!guid || changeset.guid === guid) {
        changeset.rollback();
        if (changeset.data.isNew) {
          changeset.data.unloadRecord();
        }
      }
    }

    // update/clear the changesets
    if (guid) {
      this.localChangesets = this.localChangesets.filter((c) => c.id !== guid);
      this.comparativeProductChangesets = this.comparativeProductChangesets.filter((c) => c.guid !== guid);
    } else {
      this.localChangesets = [];
      this.comparativeProductChangesets = [];
    }
  }

  updateBranchAmount(changesetId, key, value) {
    this.updateChangesetData(changesetId, key, JSON.stringify(value));
  }

  /**
   * Adds a new changesetDTO to the array of tracked changesets of this service
   * @param {LocalChangeset} changeset
   */
  updateChangesets(changeset) {
    // check if we have an existing changeset for the passed changeset id and remove the existing entry as we will add the new one later on
    // doubled entries will cause problems when saving the changesets (BE error custom-marketing-content already exists)
    let existingChangeset = this.localChangesets.find((c) => c.id === changeset.id);
    if (existingChangeset) {
      let index = this.localChangesets.indexOf(existingChangeset);
      this.localChangesets.splice(index, 1);
    }

    this.localChangesets = [...this.localChangesets, changeset];
  }

  /**
   * Updates a property of a localChangeset with the given value
   * @param {string} changesetId id of the customMarketingContent (if persisted) or local guid (if new)
   * @param {string} key name of the attribute to be updated
   * @param {Object} value value to be inserted into the attribute
   */
  updateChangesetData(changesetId, key, value) {
    let changeset = this.localChangesets.find((c) => c.id === changesetId);
    changeset.modelChangeset.set(key, value);
  }

  //
  // everything related to comparative products
  //

  /**
   * Loads and updates the comparative products for the given custom marketing content.
   *
   * @typedef {Object} ComparativeProductLoadingOptions
   * @property {Promise<ComparativeProduct[]>} comparativeProducts - list (promise) of existing comparative products for the given custom marketing content if present
   * @property {string} guid - guid of the custom marketing content
   * @property {Object} query - query to be used for loading the comparative products if no existing comparative products are present
   * @property {CustomMarketingContent} recipientContent - custom marketing content of the recipient of the preview
   *
   * @param {ComparativeProductLoadingOptions} loadingOptions
   * @returns {Changeset[]} changesets of {@link ComparativeProductModel}s for the given custom marketing content
   */
  @task
  @waitFor
  *loadComparativeProductsTask({ comparativeProducts, guid, query, recipientContent }) {
    let comparativeProductsList = yield comparativeProducts;
    let comparativeProductsChangesetList = [];

    try {
      if (isNone(comparativeProductsList)) {
        comparativeProductsList = yield this.store.query('comparative-product', query);
      }

      for (let cProduct of comparativeProductsList) {
        let searchProduct = cProduct.searchProduct;

        // if no search product is found, we can safely assume that there will be no product for this comparative product,
        // as all the data for the search product was included in the comparative product requests
        if (!isPresent(searchProduct)) {
          continue;
        }

        cProduct.guid = guid;
        let product = cProduct;

        // if we need to copy existing comparative products to the custom marketing content of the receiver
        // this is necessary when the current user is a receiver of the preview and wants to "edit" the existing custom marketing content added from the preview creator
        if (recipientContent) {
          let cProductAttributes = {};
          for (let [name] of ComparativeProductModel.attributes.entries()) {
            cProductAttributes[name] = cProduct[name];
          }

          product = this.store.createRecord('comparative-product', {
            ...cProductAttributes,
            customMarketingContent: recipientContent,
            guid,
            searchProduct,
          });
        }

        let validations = this.getValidationsForComparativeProductChangeset();
        let changeset = createChangeset(product, validations);
        comparativeProductsChangesetList.push(changeset);
      }

      this.comparativeProductChangesets = [...this.comparativeProductChangesets, ...comparativeProductsChangesetList];
      return comparativeProductsChangesetList;
    } catch (error) {
      this.errors.handle(error);
    }
  }

  /**
   * Saves the comparative products of the custom marketing content for a given guid (or all).
   * Removes/Destroys the ones marked for deletion.
   *
   * @param {string} guid - either the id of already persisted records or a local guid
   */
  @task
  @waitFor
  *saveComparativeProducts(guid) {
    let comparativeProductPromises = [];
    let changesets = guid
      ? this.comparativeProductChangesetChanges.filter((changeset) => changeset.guid === (guid || undefined))
      : this.comparativeProductChangesetChanges;

    for (let changeset of changesets) {
      //TODO should be done in chunks --> see ticket #30151
      let markedForDeletion = changeset.get('markedForDeletion');
      let promise = isPresent(markedForDeletion) ? changeset.data.destroyRecord() : changeset.save();
      comparativeProductPromises.push(promise);
      if (markedForDeletion) {
        let comparativeProductChangesets = [...this.comparativeProductChangesets];
        let index = comparativeProductChangesets.indexOf(changeset);
        comparativeProductChangesets.splice(index, 1);
        this.comparativeProductChangesets = comparativeProductChangesets;
      }
    }

    yield all(comparativeProductPromises);
  }

  /**
   * Creates a new comparative product and adds it in form of a changeset including validations to the list of
   * comparative products of the custom marketing content
   *
   * because we can't have a direct relationship with Product nor SearchProduct on the ComparativeProduct model,
   * we will need to get the eans from the ComparativeProducts and then load the SearchProducts to display the
   * Product title in the comparative title area.
   *
   * @typedef {Object} ComparativeProductCreationOptions
   * @property {Changeset} changeset - the actual changeset of the model (customMarketingContent)
   * @property {CustomMarketingContentModel} model - Ember Data record of the customMarketingContent
   * @property {string} guid -  either the id of already persisted records or a local guid
   * @property {Object} query - query object to be used for the search product request
   * @property {number} nextIndex - the index of the comparative product to be created (will be saved as position)
   *
   * @param {ComparativeProductCreationOptions} creationOptions
   * @returns {Changeset} the changeset of the newly created comparative product
   */
  @task
  @waitFor
  *validateAndCreateComparativeProductTask({ changeset, model, guid, query, nextIndex }) {
    try {
      let searchProducts = yield this.store.query('search-product', query);
      let searchProduct = searchProducts?.[0] ?? null;

      if (searchProduct) {
        let comparativeProduct = yield this.store.createRecord('comparative-product', {
          customMarketingContent: model,
          ean: searchProduct.gtin ?? searchProduct.isbn.replaceAll('-', ''),
          guid,
          position: nextIndex,
          searchProduct,
        });

        let validations = this.getValidationsForComparativeProductChangeset();
        let productChangeset = yield createChangeset(comparativeProduct, validations);

        this.comparativeProductChangesets = [...this.comparativeProductChangesets, productChangeset];

        return productChangeset;
      } else {
        changeset.addError('isbn', 'invalidIdentifier');
      }
    } catch (error) {
      this.errors.handle(error);
    }
  }

  //
  // abstract functions and tasks to be implemented by child services
  //

  /**
   * Creates a POJO of attributes of an customMarketingContent that can be used to create a new changeset with existing changes,
   * e.g. when cloning a customMarketingContent for a previewReceiver.
   *
   * @param model Ember Data record of a custom-marketing-content
   * @param isNew if the target changeset is for a new customMarketingContent (cloned for a receiver)
   * @returns POJO with stringyfied attributes and relationships of the provided custom-marketing-content
   */
  getDataForChangeset(/* model, isNew = false */) {
    return {};
  }

  /**
   * Creates a validation object to be used when generating the changeset for a custom-marketing-content.
   *
   * @returns validations object for the changeset
   */
  getValidationsForChangeset(/* model, defaultDispoList */) {
    return {};
  }

  /**
   * Creates a validation object to be used when generating the changeset for a comparative-product.
   *
   * @returns validations object for the changeset
   */
  getValidationsForComparativeProductChangeset(/* model */) {
    return {};
  }

  @task
  @waitFor
  *saveRelatedEntitiesTask(/* model, recordsToSave */) {}

  /**
   * Updates the records that need saving or the model with data from the changeset.
   *
   * @param changeset Ember Data changeset with the changes to be saved
   * @param model Ember Data record of a custom-marketing-content
   * @param recordsToSave array of Ember Data records that need to be saved
   */
  @task
  @waitFor
  *updateRecordsAndModelForSavingTask(/* changeset, model, recordsToSave*/) {}
}
