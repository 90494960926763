import { action } from '@ember/object';
import { service } from '@ember/service';
import queryParamsPack from '@mvb/tix-ui/utils/query-params-pack';
import queryParamsUnpack from '@mvb/tix-ui/utils/query-params-unpack';
import Route from '@ember/routing/route';

export default class PreviewsIndexRoute extends Route {
  @service customMarketingContent;
  @service('search-previews') searchService;
  @service session;
  @service router;

  queryParams = {
    qp: {
      refreshModel: true,
    },
    sentPreviews: {
      refreshModel: false,
    },
  };

  beforeModel(transition) {
    this.session.requireAuthentication(transition);
  }

  @action
  didTransition() {
    this.search();
  }

  resetController(_, isExiting) {
    if (isExiting) {
      this.searchService.reset();
      this.customMarketingContent.unloadServices();
    }
  }

  search() {
    let params = this.paramsFor(this.routeName);
    if (params.sentPreviews) {
      this.handleSentPreviewParam(params);
    }
    this.searchService.search(null, { replaceRoute: true }, queryParamsUnpack(params));
  }

  handleSentPreviewParam(params) {
    let sentPreviews = params.sentPreviews;
    delete params.sentPreviews;
    let unpacked = queryParamsUnpack(params);
    unpacked.sentPreviews = sentPreviews;
    let queryParamsPacked = queryParamsPack(unpacked);
    queryParamsPacked.sentPreviews = null;
    this.router.transitionTo('previews', { queryParams: queryParamsPacked });
  }
}
