import { all, didCancel, task } from 'ember-concurrency';
import { DateTime } from 'luxon';
import { isNone, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import Service, { service } from '@ember/service';
import sortBy from 'lodash-es/sortBy';

const POLL_INTERVAL = 60_000;
export default class CollectionOfGoodsService extends Service {
  @service errors;
  @service store;
  @service user;

  @tracked nextLoadTimestamp;

  get assortmentSuggestions() {
    let assortmentOptions = this.current.default.map((assortment) => {
      return {
        collectionOfGoods: assortment,
        term: `${assortment.value} - ${assortment.name}`,
      };
    });

    return sortBy(assortmentOptions, 'term');
  }

  get current() {
    return (
      this.loadTask.lastSuccessful?.value ?? {
        default: [],
        specific: [],
      }
    );
  }

  @task({ drop: true })
  @waitFor
  *loadTask(mvbId, update = false) {
    let partyId = this.user.selectedParty?.party?.id ?? null;

    try {
      let defaultGoodsPromise;
      let specificGoodsPromise;

      // the default goods will always be loaded as they are needed for the orderlines
      if (partyId) {
        defaultGoodsPromise = this.loadGoodsByPartyIdTask.perform(partyId, update);
      }

      // in case we have a specific mvbId passed (e.g. for HUG assortments for custom marketing contents)
      // we have an extra request so nothing gets overwritten
      if (mvbId) {
        specificGoodsPromise = this.loadGoodsByMvbIdTask.perform(mvbId, update);
      }

      let [defaultGoods, specificGoods] = yield all([defaultGoodsPromise, specificGoodsPromise]);

      this.nextLoadTimestamp = DateTime.now().ts + POLL_INTERVAL;

      return {
        default: defaultGoods?.value ?? [],
        specific: specificGoods?.value ?? [],
        mvbId,
        partyId,
      };
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  @waitFor
  *loadGoodsByPartyIdTask(partyId, update) {
    if (!partyId) {
      return [];
    }

    let lastSuccessfulValue = this.loadGoodsByPartyIdTask.lastSuccessful?.value;
    let hasLoadedValues = lastSuccessfulValue?.partyId === partyId;

    if (hasLoadedValues && !update) {
      return lastSuccessfulValue;
    }

    try {
      let result = yield this.store.query('collection-of-goods', {
        filter: {
          partyId,
        },
      });

      return {
        value: result,
        partyId,
      };
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ drop: true })
  @waitFor
  *loadGoodsByMvbIdTask(mvbId, update) {
    if (!mvbId) {
      return [];
    }

    let lastSuccessfulValue = this.loadGoodsByMvbIdTask.lastSuccessful?.value;
    let hasLoadedValues = lastSuccessfulValue?.mvbId === mvbId;

    if (hasLoadedValues && !update) {
      return lastSuccessfulValue;
    }

    try {
      let result = yield this.store.query('collection-of-goods', {
        filter: {
          mvbId,
        },
      });

      return {
        value: result,
        mvbId,
      };
    } catch (error) {
      this.errors.handle(error);
    }
  }

  @task({ restartable: true })
  @waitFor
  *pollForChangesTask() {
    if (!this.nextLoadTimestamp || this.nextLoadTimestamp < DateTime.now().ts) {
      yield this.loadTask.perform(this.user.selectedParty?.party?.mvbId);
    }
  }

  async arrangeGroups(assortmentGroups, seed) {
    seed = seed || [];
    let children = [];
    await all(
      assortmentGroups.map(async (group) => {
        if (seed.some((group_) => group.id === group_.id)) {
          return;
        }
        let name = group.name;
        let parent = await group.get('parent');
        let groupChildren = (await group.get('subCollections')) ?? [];
        if (isPresent(parent)) {
          name = `${parent.localName}\\${name}`;
        }

        group.localName = name;

        let assortment = {
          name,
          id: group.value,
        };
        children.push(...groupChildren);
        seed.push(assortment);
      })
    );

    if (children.length > 0) {
      await this.arrangeGroups(children, seed);
    }
    return seed;
  }

  async getOrphans(assortmentGroups) {
    let orphans = [];

    await all(
      assortmentGroups.map(async (group) => {
        let parent = await group.get('parent');
        if (isNone(parent)) {
          orphans.push(group);
        }
      })
    );
    return orphans;
  }

  async getSortedAssortmentGroups() {
    let assortmentGroups = this.current.specific;
    let orphans = await this.getOrphans(assortmentGroups);
    let groups = await this.arrangeGroups(orphans);
    return sortBy(groups, 'name');
  }

  async load() {
    try {
      return await this.loadTask.perform(...arguments);
    } catch (error) {
      if (didCancel(error)) {
        return this.loadTask.last;
      } else {
        throw error;
      }
    }
  }
}
