define("@ember-data/adapter/-private", ["exports", "@ember/debug", "@ember-data/adapter/build-url-mixin-18db8c8b"], function (_exports, _debug, _buildUrlMixin18db8c8b) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BuildURLMixin", {
    enumerable: true,
    get: function () {
      return _buildUrlMixin18db8c8b.B;
    }
  });
  _exports.determineBodyPromise = determineBodyPromise;
  _exports.fetch = getFetchFunction;
  _exports.parseResponseHeaders = parseResponseHeaders;
  _exports.serializeIntoHash = serializeIntoHash;
  _exports.serializeQueryParams = serializeQueryParams;
  _exports.setupFastboot = setupFastboot;
  const newline = /\r?\n/;
  function parseResponseHeaders(headersString) {
    const headers = Object.create(null);
    if (!headersString) {
      return headers;
    }
    const headerPairs = headersString.split(newline);
    for (let i = 0; i < headerPairs.length; i++) {
      let header = headerPairs[i];
      let j = 0;
      let foundSep = false;
      for (; j < header.length; j++) {
        if (header.charCodeAt(j) === 58 /* ':' */) {
          foundSep = true;
          break;
        }
      }
      if (foundSep === false) {
        continue;
      }
      let field = header.substring(0, j).trim();
      let value = header.substring(j + 1, header.length).trim();
      if (value) {
        let lowerCasedField = field.toLowerCase();
        headers[lowerCasedField] = value;
        headers[field] = value;
      }
    }
    return headers;
  }
  function continueOnReject(promise) {
    return Promise.resolve(promise).catch(e => e);
  }

  /*
   * Function that always attempts to parse the response as json, and if an error is thrown,
   * returns `undefined` if the response is successful and has a status code of 204 (No Content),
   * or 205 (Reset Content) or if the request method was 'HEAD', and the plain payload otherwise.
   */
  function _determineContent(response, requestData, payload) {
    let ret = payload;
    let error = null;
    if (!response.ok) {
      return payload;
    }
    let status = response.status;
    let payloadIsEmpty = payload === '' || payload === null;
    let statusIndicatesEmptyResponse = status === 204 || status === 205 || requestData.method === 'HEAD';
    if (response.ok && (statusIndicatesEmptyResponse || payloadIsEmpty)) {
      return;
    }
    try {
      ret = JSON.parse(payload);
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        return e;
      }
      e.payload = payload;
      error = e;
    }
    if (error) {
      return error;
    }
    return ret;
  }
  function determineBodyPromise(response, requestData) {
    // response.text() may resolve or reject
    // it is a native promise, may not have finally
    return continueOnReject(response.text()).then(payload => _determineContent(response, requestData, payload));
  }
  const RBRACKET = /\[\]$/;
  function isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  /*
   * Helper function that turns the data/body of a request into a query param string.
   * This is directly copied from jQuery.param.
   */
  function serializeQueryParams(queryParamsObject) {
    let s = [];
    function buildParams(prefix, obj) {
      let i, len, key;
      if (prefix) {
        if (Array.isArray(obj)) {
          for (i = 0, len = obj.length; i < len; i++) {
            if (RBRACKET.test(prefix)) {
              add(s, prefix, obj[i]);
            } else {
              buildParams(prefix + '[' + (typeof obj[i] === 'object' && obj[i] !== null ? i : '') + ']', obj[i]);
            }
          }
        } else if (isPlainObject(obj)) {
          for (key in obj) {
            buildParams(prefix + '[' + key + ']', obj[key]);
          }
        } else {
          add(s, prefix, obj);
        }
      } else if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          add(s, obj[i].name, obj[i].value);
        }
      } else {
        for (key in obj) {
          buildParams(key, obj[key]);
        }
      }
      return s;
    }
    return buildParams('', queryParamsObject).join('&');
  }

  /*
   * Part of the `serializeQueryParams` helper function.
   */
  function add(s, k, v) {
    // Strip out keys with undefined value and replace null values with
    // empty strings (mimics jQuery.ajax)
    if (v === undefined) {
      return;
    } else if (v === null) {
      v = '';
    }
    v = typeof v === 'function' ? v() : v;
    s[s.length] = `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
  }
  let _fetch = null;
  let REQUEST = null;
  function getFetchFunction() {
    // return cached fetch function
    if (_fetch !== null) {
      return _fetch();
    }

    // grab browser native fetch if available, or global fetch if otherwise configured
    if (typeof fetch === 'function') {
      // fallback to using global fetch
      _fetch = () => fetch;

      /* global FastBoot */
      // grab fetch from node-fetch
    } else if (typeof FastBoot !== 'undefined') {
      try {
        const nodeFetch = FastBoot.require('node-fetch');
        const httpRegex = /^https?:\/\//;
        const protocolRelativeRegex = /^\/\//;

        // eslint-disable-next-line no-inner-declarations
        function parseRequest(request) {
          if (request === null) {
            throw new Error("Trying to fetch with relative url but the application hasn't finished loading FastBootInfo, see details at https://github.com/ember-cli/ember-fetch#relative-url");
          }
          // Old Prember version is not sending protocol
          const protocol = request.protocol === 'undefined:' ? 'http:' : request.protocol;
          return [request.get('host'), protocol];
        }

        // eslint-disable-next-line no-inner-declarations
        function buildAbsoluteUrl(url) {
          if (protocolRelativeRegex.test(url)) {
            let [host] = parseRequest(REQUEST);
            url = host + url;
          } else if (!httpRegex.test(url)) {
            let [host, protocol] = parseRequest(REQUEST);
            url = protocol + '//' + host + url;
          }
          return url;
        }

        // eslint-disable-next-line no-inner-declarations
        function patchedFetch(input, options) {
          if (input && input.href) {
            input.url = buildAbsoluteUrl(input.href);
          } else if (typeof input === 'string') {
            input = buildAbsoluteUrl(input);
          }
          return nodeFetch(input, options);
        }
        _fetch = () => patchedFetch;
      } catch (e) {
        throw new Error(`Unable to create a compatible 'fetch' for FastBoot with node-fetch`);
      }
    }
    (false && !(_fetch) && (0, _debug.assert)(`Cannot find a 'fetch' global and did not detect FastBoot.`, _fetch));
    return _fetch();
  }
  function setupFastboot(fastBootRequest) {
    REQUEST = fastBootRequest;
  }
  function serializeIntoHash(store, modelClass, snapshot, options = {
    includeId: true
  }) {
    const serializer = store.serializerFor(modelClass.modelName);
    (false && !(serializer) && (0, _debug.assert)(`Cannot serialize record, no serializer defined`, serializer));
    if (typeof serializer.serializeIntoHash === 'function') {
      const data = {};
      serializer.serializeIntoHash(data, modelClass, snapshot, options);
      return data;
    }
    return serializer.serialize(snapshot, options);
  }
});