import { all } from 'ember-concurrency';
import { assert, runInDebug } from '@ember/debug';
import { packArray, packSingle } from '@mvb/tix-ui/utils/linked-parties';
import { service } from '@ember/service';
import queryParamsReset from '@mvb/tix-ui/utils/query-params-reset';
import Route from '@ember/routing/route';

export default class BackofficePartiesPartyRoute extends Route {
  @service store;

  queryParams = {
    page: {
      refreshModel: true,
    },
  };

  modelName = '';

  constructor() {
    super(...arguments);
    runInDebug(() => {
      assert(
        'Classes based on BackofficePartiesPartyRoute must set modelName so the model hook can retrieve the correct items',
        this.constructor.name !== 'BackofficePartiesPartyRoute' && this.modelName === ''
      );
    });
  }

  async model({ party_id: partyId, page }) {
    let [party, history] = await all([
      this.store.findRecord('party', partyId, { include: 'parent,imprints,affiliates,affiliations' }),
      this.store.query('premium-history', {
        page: {
          number: page,
        },
        filter: {
          partyId,
        },
      }),
    ]);
    let arrangedParties = [];

    // we do not expect that all parties have all of these relationships but the decision was made to attempt to render a table for every relationship that might be passed from the backend, rather than explicitly rendering only the relationships we expect.
    packSingle({ name: 'parent', party: party.parent, arrangedParties, calculateStatus: true, includeInactive: true });
    packArray({
      name: 'imprints',
      parties: party.imprints,
      arrangedParties,
      calculateStatus: true,
      includeInactive: true,
    });
    packArray({
      name: 'affiliations',
      parties: party.affiliations,
      arrangedParties,
      calculateStatus: true,
      includeInactive: true,
    });
    packArray({
      name: 'affiliates',
      parties: party.affiliates,
      arrangedParties,
      calculateStatus: true,
      includeInactive: true,
    });

    return {
      arrangedParties,
      party,
      history,
    };
  }

  resetController(_, isExiting) {
    if (isExiting) {
      queryParamsReset({ route: this });
    }
  }

  setupController(controller, { party, history }) {
    controller.set('model', party);
    controller.set('history', history);
  }
}
