import { all } from 'ember-concurrency';
import { service } from '@ember/service';
import Route from '@ember/routing/route';

export default class PartyPermissionsRoute extends Route {
  @service api;
  @service abilities;
  @service store;
  @service user;

  async model() {
    if (this.abilities.can('party.viewPermissions')) {
      let [permissionSets, allPermissions] = await all([
        this.store.query('permission-set', {
          filter: {
            partyId: this.user.selectedParty?.belongsTo('party').id(),
          },
        }),
        this.api.get('/enum/party-permissions'),
      ]);

      let adminPermissionSet = permissionSets.find((ps) => ps.name === 'admin' && ps.predefined);

      if (!adminPermissionSet) {
        return {
          permissionSets: [],
          permissions: [],
        };
      }

      // locked permissions need to be set when creating a new permission set, even when no template has been chosen
      let memberPermissionSet =
        permissionSets.find((ps) => ps.name === 'member' && ps.predefined) ?? adminPermissionSet;
      let minimalLockedPermissions = allPermissions
        .flatMap((category) => category.values)
        .filter((permission) => memberPermissionSet.permissions.includes(permission.value) && permission.locked)
        .map((permission) => permission.value);

      // Filter so we only show permission labels that are present in the current admin permission-set.
      // Filter permissions that are locked because these must not be shown in the frontend.
      // We also hide categories which have no visible permissions.
      let permissions = allPermissions
        .map((category) => ({
          ...category,
          values: category.values.filter(
            (permission) => adminPermissionSet.permissions.includes(permission.value) && !permission.locked
          ),
        }))
        .filter((category) => category.values.length);

      let sortedPermissionSets = permissionSets.slice().sort((a, b) => {
        if (a.predefined && !b.predefined) {
          return -1;
        }
        if (!a.predefined && b.predefined) {
          return 1;
        }

        if (a.predefined && a.name === 'admin') {
          return -1;
        }
        if (b.predefined && b.name === 'admin') {
          return 1;
        }

        if (a.symbol > b.symbol) {
          return 1;
        }
        if (a.symbol < b.symbol) {
          return -1;
        }

        return 0;
      });

      return {
        permissionSets: sortedPermissionSets,
        permissions,
        minimalLockedPermissions,
      };
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.stopEditing();
    }
  }
}
