import config from '@mvb/tix-ui/config/environment';

export const ASSIGNED_PARTY_PERMISSION = {
  ADMIN: 'admin',
  MEMBER: 'member',
};

export const ASSET_ADDITIONAL_INFO_TYPES = {
  types:
    'image/jpeg,image/png,image/gif,image/tiff,application/pdf,application/epub+zip,audio/mpeg,audio/mp3,audio/wav,audio/flac,video/mp4,video/quicktime,video/x-ms-wmv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  description: 'jpg, jpeg, png, gif, tif, tiff, pdf, epub, mp3, wav, flac, mp4, mov, avi, wmv, doc, docx, xls, xlsx',
};

export const ASSET_FILE_TYPES = {
  PROPRIETARY_AUTHOR_IMAGE: 'PROPRIETARY_AUTHOR_IMAGE',
};

// from codelist 161
export const ASSET_FORM_CODES = {
  LINKABLE: '01',
  DOWNLOADABLE: '02',
  EMBEDDABLE: '03',
};

export const ASSET_MAX_SIZE = 5 * 1024 * 1024;
export const ASSET_MAX_SIZE_20 = 20 * 1024 * 1024;

// from codelist 159
export const ASSET_MODE_CODES = {
  WIDGET: '01',
  AUDIO: '02',
  IMAGE: '03',
  TEXT: '04',
  VIDEO: '05',
  MISC: 'XX',
};

// from codelist 158
export const ASSET_TYPE_CODES = {
  COVER: '01',
  BACKCOVER: '02',
  MORECOVER: '03',
  AUTHOR: '04',
  IMAGES: '07',
  INTERVIEW: '11',
  PRESENTATION: '12',
  READING: '13',
  EXCERPT: '15',
  INTERIOR: '15',
  EXCERPT_WIDGET: '16',
  TRAILER: '26',
  WRAPPER: '29',
  LOGO: '30',
  MARKETING: '35',
  STAGE: '36',
  ADVERTISING: '37', //PROMOTIONAL_EVENT_MATERIAL
  TUTORIAL: '39',
  MISC: 'XX',
};

export const ASSET_TYPES = {
  COMMON: 'COMMON',
  PROPRIETARY: 'PROPRIETARY',
};

export const ASSET_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const AUDIENCE_RANGE_CODES = {
  INTEREST_MONTH: '16',
  INTEREST_YEAR: '17',
  READING: '18',
};

export const AUDIENCE_TYPES = {
  MAIN: '01',
  FSK: '05',
};

export const AVAILABILITY = {
  AVAILABLE: 'available',
  LIMITED: 'limited',
  UNAVAILABLE: 'unavailable',
};

export const AVAILABILITY_STATES = {
  AVAILABLE: '20',
  NOT_AVAILABLE: '40',
  NOT_YET_AVAILABLE: '10',
  REMOVED_FROM_SALE: '46',
};

export const BACKGROUND_PROCESS_POLLING_INTERVAL = config.environment === 'test' ? 1 : 3000;

export const SHOULD_POLL_BACKGROUND_PROCESS = config.environment !== 'test';

export const BACKGROUND_PROCESS_STATUS = {
  ABORTED: 'ABORTED',
  ERROR: 'ERROR',
  FINISHED: 'FINISHED',
  NEW: 'NEW',
  RUNNING: 'RUNNING',
};

export const BACKGROUND_PROCESS_TYPES = {
  PREVIEW_CLONING: 'PREVIEW_CLONING',
  BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD: 'BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD',
};

export const BESTSELLER_URLS = {
  Belletristik: {
    eBook: 'https://www.boersenblatt.net/news/bestseller/e-book',
    HC: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-belletristik-bestseller-hardcover',
    PB: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-belletristik-bestseller-paperback',
    TB: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-belletristik-bestseller-taschenbuch',
  },
  Bilderbücher: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-bilderbuecher',
  BookTok: 'https://www.boersenblatt.net/bestseller/booktok',
  'Essen-Trinken-Ernährung':
    'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-ratgeber-essen-trinken-ernaehrung',
  Hörbuch: 'https://www.boersenblatt.net/news/bestseller/hoerbuch',
  Indie: 'https://www.boersenblatt.net/news/bestseller/independent',
  Jugendbuch: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-jugendbuecher',
  Kinderbuch: 'https://www.boersenblatt.net/news/bestseller/kinder-und-jugendbuch',
  'Kinder-Jugendsachbuch':
    'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-kinder-und-jugendsachbuch',
  Krimi: 'https://www.boersenblatt.net/news/bestseller/krimi',
  'Zeit-Leo': 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestenliste-mit-zeit-leo',
  Leselotse: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestenliste-leselotse',
  Ratgeber: 'https://www.boersenblatt.net/news/bestseller/ratgeber',
  Sachbuch: {
    eBook: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-e-books-sachbuch',
    HC: 'https://www.boersenblatt.net/news/bestseller/sachbuch',
    PB: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-sachbuch-bestseller-paperback',
    TB: 'https://www.boersenblatt.net/aktuelle-boersenblatt-bestsellerliste-sachbuch-bestseller-taschenbuch',
  },
};

export const BESTSELLER_CATEGORIES_MONTHLY = [
  {
    name: 'Kinder- und Jugendbuch',
    subcategories: [
      {
        name: 'Kinderbuch',
        url: BESTSELLER_URLS.Kinderbuch,
      },
      {
        name: 'Jugendbuch',
        url: BESTSELLER_URLS.Jugendbuch,
      },
      {
        name: 'Bilderbuch',
        url: BESTSELLER_URLS.Bilderbücher,
      },
      {
        name: 'Kinder- und Jugendsachbuch',
        url: BESTSELLER_URLS['Kinder-Jugendsachbuch'],
      },
      {
        name: 'ZEIT Leo',
        url: BESTSELLER_URLS['Zeit-Leo'],
      },
      {
        name: 'Leselotse',
        url: BESTSELLER_URLS.Leselotse,
      },
    ],
  },
  {
    name: 'Krimi',
    subcategories: [
      {
        name: 'Krimi',
        url: BESTSELLER_URLS.Krimi,
      },
    ],
  },
  {
    name: 'Independent ',
    subcategories: [
      {
        name: 'Independent',
        url: BESTSELLER_URLS.Indie,
      },
    ],
  },
  {
    name: 'Hörbuch',
    subcategories: [
      {
        name: 'Hörbuch',
        url: BESTSELLER_URLS.Hörbuch,
      },
    ],
  },
  {
    name: 'E-Book',
    subcategories: [
      {
        name: 'Belletristik',
        url: BESTSELLER_URLS.Belletristik.eBook,
      },
      {
        name: 'Sachbuch',
        url: BESTSELLER_URLS.Sachbuch.eBook,
      },
    ],
  },
];
export const BESTSELLER_CATEGORIES_WEEKLY = [
  {
    name: 'Belletristik',
    subcategories: [
      {
        name: 'Hardcover',
        url: BESTSELLER_URLS.Belletristik.HC,
      },

      {
        name: 'Paperback',
        url: BESTSELLER_URLS.Belletristik.PB,
      },

      {
        name: 'Taschenbuch',
        url: BESTSELLER_URLS.Belletristik.TB,
      },
    ],
  },
  {
    name: 'Sachbuch',
    subcategories: [
      {
        name: 'Hardcover',
        url: BESTSELLER_URLS.Sachbuch.HC,
      },

      {
        name: 'Paperback',
        url: BESTSELLER_URLS.Sachbuch.PB,
      },

      {
        name: 'Taschenbuch',
        url: BESTSELLER_URLS.Sachbuch.TB,
      },
    ],
  },
  {
    name: 'Ratgeber',
    subcategories: [
      {
        name: 'Ratgeber',
        url: BESTSELLER_URLS.Ratgeber,
      },
      {
        name: 'Essen, Trinken & Ernährung',
        url: BESTSELLER_URLS['Essen-Trinken-Ernährung'],
      },
    ],
  },
  {
    name: 'BookTok',
    subcategories: [
      {
        name: 'BookTok',
        url: BESTSELLER_URLS.BookTok,
      },
    ],
  },
];

export const BESTSELLER_STATE = {
  NEW: 'NEW',
  UP: 'UP',
  DOWN: 'DOWN',
  SAME: 'SAME',
};

export const CLASSIFICATION_TYPES = {
  BISAC: '10',
  BIC: '12',
  DDC: '18',
  KEYWORD: '20',
  COG: '26', // cagetory of goods
  READING_RATIONALE: 'B8',
  THEMA_SUBJECT: '93',
  THEMA_GEO: '94',
  THEMA_LANGUGAGE: '95',
  THEMA_TIME: '96',
  THEMA_PEDAGOGICAL: '97',
  THEMA_AGE: '98',
  THEMA_STIL: '99',
};

export const CODELISTS = {
  PRODUCT_FORM: '150',
  PRODUCT_FORM_DETAILS: '175',
};

export const COG_TEMPLATEFILE_WITH_INDEX = 'VLBTIX_WG_Vorlage_mit_Index.xlsx';
export const COG_TEMPLATEFILE_WITHOUT_INDEX = 'VLBTIX_WG_Vorlage_ohne_Index.xlsx';

export const CONTACT_TYPES = {
  ADMINISTRATION: 'ADMINISTRATION',
  AGENT: 'AGENT',
  EVENTS: 'EVENTS',
  PRODUCTION: 'PRODUCTION',
  PUBLISHER_DISTRIBUTION: 'PUBLISHER_DISTRIBUTION',
  PUBLISHER_MARKETING: 'PUBLISHER_MARKETING',
  PUBLISHER_PRESS: 'PUBLISHER_PRESS',
  PUBLISHER_PROGRAM: 'PUBLISHER_PROGRAM',
  PUBLISHER_RIGHTS: 'PUBLISHER_RIGHTS',
};

export const CURRENCIES_DACH = {
  EUR_DE: 'EUR_DE',
  EUR_AT: 'EUR_AT',
  CHF_CH: 'CHF_CH',
};

export const CURRENCIES_MX = {
  MXN_MX: 'MXN_MX',
};

export const CUSTOM_MARKETING_CONTENT_DEFAULT_BOOKING_OPTION = {
  HUGENDUBEL: 'H01',
};

export const CUSTOM_MARKETING_CONTENT_KEY = {
  ASSORTMENT: 'assortment',
  BRANCH_AMOUNTS: 'branchRecommendedOrderAmounts',
  CATEGORY: 'category',
  DISPLAY: 'display',
  HUG_AGES: 'hugAges',
  HUG_COMMENTS: 'hugComments',
  HUG_DISPOLIST_NOTES: 'hugDispoListNotes',
  HUG_INDEXED_MARKETING_RECOMMENDATION: 'hugMarketingRecommendation__',
  HUG_INDEXED_MARKETING_RECOMMENDATION_NOTE: 'hugMarketingRecommendationNote__',
  HUG_INDEXED_MARKETING_RECOMMENDATION_BOOKING: 'hugMarketingRecommendationBooking__',
  HUG_INDEXED_MARKETING_RECOMMENDATION_STATUS: 'hugMarketingRecommendationStatus__',
  HUG_INDEXED_MARKETING_RECOMMENDATION_EDITED_BY: 'hugMarketingRecommendationEditedBy__',
  HUG_INDEXED_MARKETING_RECOMMENDATION_EDITED_AT: 'hugMarketingRecommendationEditedAt__',
  HUG_LISTING_RECOMMENDATIONS: 'hugListingRecommendations',
  HUG_MARKETING_EVENTS: 'hugMarketingEvents',
  HUG_REGIONS: 'hugRegions',
  HUG_SEASONS: 'hugSeasons',
  INDEXED_MARKETING_RECOMMENDATION: 'marketingRecommendation__',
  INDEXED_MARKETING_RECOMMENDATION_NOTE: 'marketingRecommendationNote__',
  INDEXED_MARKETING_RECOMMENDATION_BOOKING: 'marketingRecommendationBooking__',
  KEYWORDS: 'keywords',
  LISTING_RECOMMENDATIONS: 'listingRecommendations',
  MARKETING_RECOMMENDATIONS: 'marketingRecommendations',
  MARKETING_RECOMMENDATIONS_TRC: 'trcMarketingRecommendations',
  MARKETING_SUBSIDY_STATUSES: 'marketingSubsidyStatuses',
  MEDIA_INDEX: 'mediaIndex',
  PACKAGING_EAN: 'packagingEan',
  PACKAGING_UNIT: 'packagingUnit',
  ORDER_QUANTITY_UNIT: 'orderQuantityUnit',
  RECOMMENDED_ORDER_AMOUNT_MARKETING_EXPECTATION: 'recommendedOrderAmountMarketingExpectation',
  RECOMMENDED_ORDER_AMOUNT_POTENTIAL: 'recommendedOrderAmountPotential',
  SEASON_CODE: 'seasonCode',
  WEBSHOP_LINK: 'webshopLink',
  TAGS: 'tags',
  TRC_CATEGORY_OF_GOODS: 'trcCategoryOfGoods',
};

export const CUSTOM_MARKETING_CONTENT_MAX_MARKETING_RECOMMENDATIONS = {
  HUGENDUBEL: 2,
};

export const CUSTOM_MARKETING_CONTENT_ORDER_QUANTITY_UNIT = {
  KAR: 'KAR',
  ST: 'ST',
};

export const CUSTOM_MARKETING_CONTENT_RECOMMENDATION_STATUS = {
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const CUSTOM_MARKETING_CONTENT_TYPE = {
  BOOKSTORE: 'BOOKSTORE',
  INDIVIDUAL: 'INDIVIDUAL',
  THALIA: 'THALIA',
  HUGENDUBEL: 'HUGENDUBEL',
};

export const CUSTOMER_SERVICE_EMAIL = 'vlbtix@mvb-online.de';

export const DATE_TYPES = {
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const DEBOUNCE = config.environment === 'test' ? 1 : 1000;
export const DEBOUNCE_SMALL = config.environment === 'test' ? 1 : 300;

export const DEFAULT_TOOLTIP_SIDE = 'right';

export const DELAY_NOTIFICATION = 500;
export const DELAY_RENDER = 100;

export const DIMENSIONS = {
  COVER_HEIGHT: 400,
  COVER_WIDTH: 1000,
  LOGO_WIDTH: 250,
  PROFILE_HEIGHT: 256,
  PROFILE_WIDTH: 256,
  STAGE_HEIGHT: 350,
  STAGE_WIDTH: 1200,
};

export const ERPS = {
  JWWS: 'JWWS',
  EUROSALESFLOW: 'euro-Sales Flow',
};

export const ERP_SETUP_TYPES = {
  WEB_PROTOCOL: 'WEB_PROTOCOL',
  FTP: 'FTP',
};

export const EXCERPT_EXTERNAL_PROVIDERS = {
  BICMEDIA: 'bicmedia',
  BOOK2LOOK: 'book2look',
  MIDVOX: 'midvox',
  LIBREKA: 'libreka',
};

export const EXCERPT_EXTERNAL_URLS = {
  BICMEDIA:
    'https://www.bic-media.com/mobile/mobileWidget-jqm1.4.html?noMobilePreCover=yes&playAudioOnStart=no&showExtraShopButton=no&buyButton=no&https=yes&iconType=rh5&iconTypeSecondary=rh5&isbn={IDENTIFIER}',
  BOOK2LOOK:
    'https://www.book2look.com/embed/{IDENTIFIER}&euid=47102274&ruid=47102199&referurl=www.vlbtix.de&shoplinknumbers=none&biblettype=html5',
  LIBREKA: 'https://bookview.libreka.de/preview/100426/{IDENTIFIER}',
};

export const EXPECTED_VIDEO_SOURCES = {
  YOUTUBE: 'youtu',
  VIMEO: 'vimeo',
};

export const EXPORT_TYPE = {
  PDF: 'pdf',
  WEB_TO_PRINT: 'WP',
  XLSX: 'xlsx',
};

export const EXPORT_FILE_TYPES = {
  PDF: 'pdf',
  XLSX: 'xlsx',
  XML: 'xml',
};

export const FACETS = {
  GENRE_CODE: 'genrecode',
  LISTING_THALIA: 'listingThalia',
  PARTY_GENRE: 'partyGenre',
};

export const FALLBACK_COVER_URL = '/app/assets/cover/no-cover.svg';

export const FILTER_CREATED_BY_AN_ASSIGNED_COMPANY = 'createdByAnAssignedCompany';
export const FILTER_CREATED_BY_USER = 'createdByUser';
export const FILTER_DATE_FORMAT = 'yyyy-LL-dd';
export const FILTER_MIN_DATE = '1458-01-01';
export const FILTER_MAX_DATE = '2999-01-01';

export const FLAG_GROUP = {
  BOOK_GROUP: 'BOOK_GROUP',
  CAMPAIGN: 'CAMPAIGN',
  HIGHLIGHT: 'HIGHLIGHT',
  PLAIN: 'PLAIN',
  THALIA_RECOMMENDATION: 'THALIA_RECOMMENDATION',
};

export const FLAG_TRANSLATION_KEY = {
  STRING: 'string',
};

export const FORM_FEATURE_TYPES = {
  WARNING: '13',
  PIECES: '22',
  PLAYERS: '23',
  DURATION: '24',
};

export const HUGENDUBEL_MVB_ID = '5116367';

export const ICONS = {
  aa: 'headphones',
  ab: 'cassette-tape',
  ac: 'compact-disc',
  ad: 'cassette-betamax',
  ae: 'compact-disc',
  af: 'cassette-betamax',
  ag: 'compact-disc',
  ah: 'compact-disc',
  ai: 'compact-disc',
  aj: 'file-audio-download',
  ak: 'mp3-player',
  al: 'sd-card',
  am: 'compact-disc',
  an: 'file-audio',
  ao: 'cloud-music',
  az: 'volume-high',
  ba: 'book',
  bb: 'book',
  bc: 'book',
  bd: 'file-lines',
  be: 'book-open',
  bf: 'book-open',
  bg: 'book-sparkles',
  bh: 'book-user',
  bi: 'book-user',
  bj: 'book-user',
  bk: 'book-sparkles',
  bl: 'address-book',
  bm: 'book',
  bn: 'book-open',
  bo: 'map',
  bp: 'book-user',
  bz: 'book',
  ca: 'map-location',
  cb: 'map-location',
  cc: 'map-location',
  cd: 'map-location',
  ce: 'globe-stand',
  cz: 'book-atlas',
  da: 'compact-disc',
  db: 'compact-disc',
  dc: 'compact-disc',
  de: 'gamepad-modern',
  df: 'floppy-disk',
  di: 'compact-disc',
  dj: 'sd-card',
  dk: 'sd-card',
  dl: 'sd-card',
  dm: 'usb-drive',
  dn: 'compact-disc',
  do: 'compact-disc',
  dz: 'compact-disc',
  ea: 'tablet-screen-button',
  eb: 'tablet-screen-button',
  ec: 'tablet-screen-button',
  ed: 'tablet-screen-button',
  fa: 'film',
  fc: 'film',
  fd: 'file-lines',
  fe: 'film',
  ff: 'film',
  fz: 'film',
  la: 'file-certificate',
  lb: 'file-certificate',
  lc: 'file-certificate',
  jo: 'newspaper',
  ma: 'photo-film',
  mb: 'photo-film',
  mc: 'film-canister',
  mz: 'photo-film',
  pa: 'book-sparkles',
  pb: 'address-book',
  pc: 'calendar-days',
  pd: 'map-location',
  pe: 'copy',
  pf: 'pen-fancy',
  pi: 'file-music',
  pj: 'envelopes-bulk',
  pk: 'file-image',
  pl: 'book-heart',
  pm: 'folder-open',
  pn: 'image-portrait',
  po: 'presentation',
  pp: 'note-sticky',
  pq: 'images',
  pr: 'pen-fancy',
  ps: 'address-book',
  pt: 'bookmark',
  pu: 'book',
  pv: 'book',
  pz: 'dice-three',
  sa: 'books-medical',
  sb: 'books-medical',
  sc: 'books-medical',
  sd: 'books-medical',
  se: 'books-medical',
  sf: 'books-medical',
  va: 'video',
  vf: 'compact-disc',
  vi: 'compact-disc',
  vj: 'cassette-vhs',
  vk: 'cassette-betamax',
  vl: 'compact-disc',
  vm: 'compact-disc',
  vn: 'compact-disc',
  vo: 'compact-disc',
  vp: 'playstation',
  vq: 'compact-disc',
  vz: 'video',
  xa: 'images',
  xb: 'shelves',
  xc: 'shelves',
  xd: 'shelves',
  xe: 'shelves',
  xf: 'file-image',
  xg: 'credit-card-front',
  xh: 'store',
  xi: 'file-image',
  xj: 'shelves',
  xk: 'book-sparkles',
  xl: 'box-taped',
  xm: 'box-taped',
  xn: 'box-taped',
  xo: 'shelves',
  xy: 'tag',
  xz: 'images',
  za: 'dice-three',
  zb: 'child',
  zc: 'dog',
  zd: 'shapes',
  ze: 'dice',
  zf: 'shirt',
  zg: 'tablet-screen-button',
  zh: 'tablet-screen-button',
  zi: 'mp3-player',
  zj: 'puzzle-piece',
  zk: 'mug',
  zl: 'bag-shopping',
  zm: 'mug',
  zn: 'umbrella',
  zo: 'pen',
  zx: 'shapes',
  zy: 'shirt',
  zz: 'dice-three',
};

export const IDENTIFICATION_TYPE = {
  GERMAN: 'GERMAN',
  AUSTRIAN: 'AUSTRIAN',
  SWISS: 'SWISS',
  MVB: 'MVB',
  CUSTOM: 'CUSTOM',
};

export const IDENTIFIER_TYPES = {
  ORDER: '01',
  GTIN: '03',
  DOI: '06',
  ISBN: '15',
};

export const KEY_CODES = {
  ARROW_DOWN: 40,
  ARROW_UP: 38,
  ENTER: 13,
};

export const LANGUAGE_TYPES = {
  AUDIO: '08',
  MAIN: '01',
  ORIGINAL: '02',
  SUBTITLE: '09',
};

export const LOCALE = {
  DE_DE: 'de_DE',
};

export const MAX_PRODUCTS_FOR_BATCH_ADD = 2000;
export const MAX_PRODUCTS_FOR_COLLECTION = 50;
export const MAX_PRODUCTS_FOR_EXPORT = 10000;
export const MAX_PRODUCTS_FOR_PRESENTATION = 1000;
export const MAX_ENTITIES_FOR_SEARCH = 10000;

export const NOTE_TYPES = {
  GROUPS: 'GROUPS',
  PARTY: 'PARTY',
  PRIVATE: 'PRIVATE',
};

export const ORDER_CHANNEL_TYPES = {
  AGENT_DISTRIBUTION_PUBLISHER: 'AGENT_DISTRIBUTION_PUBLISHER',
  ERP: 'ERP',
  PRESTO: 'PRESTO',
  AVA: 'AVA',
  BUCHZENTRUM: 'BUCHZENTRUM',
};

export const PARTY_ADDITIONAL_RIGHTS = {
  PREVIEW_IMPORT: 'PREVIEW_IMPORT',
  WEB_TO_PRINT: 'WEB_TO_PRINT',
  MANAGE_BOOKSTORE_PREVIEWS: 'MANAGE_BOOKSTORE_PREVIEWS',
};

export const PARTY_PERMISSIONS = {
  BOOKGROUP_TITLE_UPLOAD: 'BOOKGROUP_TITLE_UPLOAD',
  COPY_PEER_INDIVIDUAL_PREVIEW: 'COPY_PEER_INDIVIDUAL_PREVIEW',
  COPY_PEER_STANDARD_PREVIEW: 'COPY_PEER_STANDARD_PREVIEW',
  CREATE_ACTIONS_SPECIALS: 'CREATE_ACTIONS_SPECIALS',
  CREATE_OWN_INDIVIDUAL_PREVIEW: 'CREATE_OWN_INDIVIDUAL_PREVIEW',
  CREATE_OWN_STANDARD_PREVIEW: 'CREATE_OWN_STANDARD_PREVIEW',
  DELETE_PEER_INDIVIDUAL_PREVIEW: 'DELETE_PEER_INDIVIDUAL_PREVIEW',
  DELETE_PEER_STANDARD_PREVIEW: 'DELETE_PEER_STANDARD_PREVIEW',
  EDIT_GROUPS: 'EDIT_GROUPS',
  EDIT_PARTY_PROFILE: 'EDIT_PARTY_PROFILE',
  EDIT_PEER_INDIVIDUAL_PREVIEW: 'EDIT_PEER_INDIVIDUAL_PREVIEW',
  EDIT_PEER_STANDARD_PREVIEW: 'EDIT_PEER_STANDARD_PREVIEW',
  EDIT_PERMISSIONS: 'EDIT_PERMISSIONS',
  EDIT_THALIA_ORDER_GRID_QUANTITIES: 'EDIT_THALIA_ORDER_GRID_QUANTITIES',
  EDIT_TITLE_DETAILS: 'EDIT_TITLE_DETAILS',
  FOXXY_ASSORTMENT_IMPORT: 'FOXXY_ASSORTMENT_IMPORT',
  FOXXY_DISPOLIST_EXPORT: 'FOXXY_DISPOLIST_EXPORT',
  HUGENDUBEL_PREVIEW_EXPORT: 'HUGENDUBEL_PREVIEW_EXPORT',
  MANAGE_BRANCHES: 'MANAGE_BRANCHES',
  MANAGE_BOOKSTORE_PREVIEWS: 'MANAGE_BOOKSTORE_PREVIEWS',
  MANAGE_CMC: 'MANAGE_CMC',
  MANAGE_COLLECTION_OF_GOODS: 'MANAGE_COLLECTION_OF_GOODS',
  MANAGE_CUSTOMER_CONTACTS: 'MANAGE_CUSTOMER_CONTACTS',
  MANAGE_DISPOLISTS: 'MANAGE_DISPOLISTS',
  MANAGE_ERP: 'MANAGE_ERP',
  MANAGE_HUG_CMC: 'MANAGE_HUG_CMC',
  MANAGE_PREVIEWS: 'MANAGE_PREVIEWS',
  MANAGE_TRC_CMC: 'MANAGE_TRC_CMC',
  MANAGE_USERS: 'MANAGE_USERS',
  MARKETINGTREE_IMPORT: 'MARKETINGTREE_IMPORT',
  ORDER: 'ORDER',
  READ_PEER_INDIVIDUAL_PREVIEW: 'READ_PEER_INDIVIDUAL_PREVIEW',
  READ_PEER_STANDARD_PREVIEW: 'READ_PEER_STANDARD_PREVIEW',
  RECEIVE_APPEAL_MAILS: 'RECEIVE_APPEAL_MAILS',
  REJECT_ADVERTISING_MATERIAL_ORDER: 'REJECT_ADVERTISING_MATERIAL_ORDER',
  SEND_PEER_INDIVIDUAL_PREVIEW: 'SEND_PEER_INDIVIDUAL_PREVIEW',
  SEND_PEER_STANDARD_PREVIEW: 'SEND_PEER_STANDARD_PREVIEW',
  SET_ADVERT_ADDRESS: 'SET_ADVERT_ADDRESS',
  SHOW_COMPARATIVE_PRODUCTS_SNIPPET: 'SHOW_COMPARATIVE_PRODUCTS_SNIPPET',
  SHOW_THALIA_ORDER_GRID: 'SHOW_THALIA_ORDER_GRID',
  THALIA_IMPORT_EXPORT_REFERENCE_PRODUCTS: 'THALIA_IMPORT_EXPORT_REFERENCE_PRODUCTS',
  THALIA_IMPORT_ORDER_RECOMMENDATIONS: 'THALIA_IMPORT_ORDER_RECOMMENDATIONS',
  UPLOAD_CATEGORY_OF_GOODS: 'UPLOAD_CATEGORY_OF_GOODS',
};

export const PARTY_TYPE = {
  BOOKSTORE: 'BOOKSTORE',
  PUBLISHER: 'PUBLISHER',
  SALES_COOP: 'SALES_COOP',
  BOOK_GROUP: 'BOOK_GROUP',
};

export const PARTY_TYPES_FOR_COMPANY_PAGES = [PARTY_TYPE.PUBLISHER, PARTY_TYPE.SALES_COOP, PARTY_TYPE.BOOK_GROUP];

export const PASSWORD_MAX_LENGTH = 50;

export const PREMIUM_STATUS = {
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
};

export const PREVIEW_EDIT_STATE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  DONE: 'DONE',
};

export const PREVIEW_SEASON = {
  '00': 'ALL_YEAR',
  Q1: 'SPRING',
  Q3: 'FALL',
  Q4: 'WINTER',
};

export const PREVIEW_STATUS = {
  DRAFT: '00',
  PUBLISHED: '01',
  PUBLISHED_NOT_SENT: '02',
  PUBLISHED_SENT: '03',
};

export const PREVIEW_TYPE = {
  BOOKSTORE_PREVIEW: 'BV',
  HUGENDUBEL: 'HT',
  INDIVIDUALVORSCHAU: 'IV',
  STANDARDVORSCHAU: 'SV',
  THALIA: 'TT',
  WEB_TO_PRINT: 'WP',
};

export const PREVIEW_CMC_TYPES = {
  TT_DE: 'TT_DE',
  TT_TRC: 'TT_TRC',
};

export const PREVIEW_TYPES_INDIVIDUAL = [
  PREVIEW_TYPE.BOOKSTORE_PREVIEW,
  PREVIEW_TYPE.INDIVIDUALVORSCHAU,
  PREVIEW_TYPE.HUGENDUBEL,
  PREVIEW_TYPE.THALIA,
];

export const PRICE_TYPES = {
  CALCULATED: '42',
  RRP_INCL_TAX: '02',
};

export const PRICE_TYPES_DACH = [
  { currency: 'EUR', country: 'DE' },
  { currency: 'EUR', country: 'AT' },
  { currency: 'CHF', country: 'CH' },
];

export const PRICE_TYPES_SWITCH = {
  FIXED: 'FIXED',
  GROSS: 'GROSS',
};

export const PRODUCT_COMPOSITION_TYPES = {
  PROMOTIONAL_PACKAGE: '30',
  SPECIAL: '11',
};

export const PRODUCT_FORM_DETAILS = {
  PAPERBACK: 'B131',
  POCKETBOOK: 'B133',
  EBOOK: 'E1',
};

export const PRODUCT_FORM_TYPES = {
  AUDIO_FILE_DOWNLOAD_OR_ONLINE: 'AN',
  AUDIO_FILE_DOWNLOAD: 'AJ',
  AUDIO_FILE_ONLINE: 'AO',
  DIGITAL_PRODUCT_LICENSE_KEY: 'DO',
  DIGITAL_SHORT: 'E',
  DIGITAL: 'EA',
  EBOOK_DOWNLOAD_OR_ONLINE: 'EB',
  EBOOK_DOWNLOAD: 'ED',
  EBOOK_ONLINE: 'EC',
  EBOOK: 'EA',
  HARDCOVER: 'BB',
  JOURNAL: 'JO',
  LICENSE_CODE_DIGITAL_PRODUCT: 'LC',
  LICENSE_DIGITAL_PRODUCT: 'LA',
  LICENSE_KEY_DIGITAL_PRODUCT: 'LB',
  PACKAGE: 'XY',
  SOFTCOVER: 'BC',
};

export const PRODUCT_FORMS_NOT_ORDERABLE = [
  PRODUCT_FORM_TYPES.AUDIO_FILE_DOWNLOAD,
  PRODUCT_FORM_TYPES.AUDIO_FILE_DOWNLOAD_OR_ONLINE,
  PRODUCT_FORM_TYPES.AUDIO_FILE_ONLINE,
  PRODUCT_FORM_TYPES.DIGITAL_PRODUCT_LICENSE_KEY,
  PRODUCT_FORM_TYPES.EBOOK,
  PRODUCT_FORM_TYPES.EBOOK_DOWNLOAD_OR_ONLINE,
  PRODUCT_FORM_TYPES.EBOOK_ONLINE,
  PRODUCT_FORM_TYPES.EBOOK_DOWNLOAD,
  PRODUCT_FORM_TYPES.JOURNAL,
  PRODUCT_FORM_TYPES.LICENSE_DIGITAL_PRODUCT,
  PRODUCT_FORM_TYPES.LICENSE_KEY_DIGITAL_PRODUCT,
  PRODUCT_FORM_TYPES.LICENSE_CODE_DIGITAL_PRODUCT,
];

export const PRODUCT_FRUITS_VALUES = {
  THALIA: 'Thalia',
  HUGENDUBEL: 'Hugendubel',
  PREMIUM: 'PREMIUM',
  BASIC: 'BASIS',
  ADMIN: 'Admin',
  ORDER_MEMBER: 'Teilnehmer mit Bestellrecht',
  MEMBER: 'Teilnehmer',
};

export const TIX_ITEM_SOURCE_TYPE = {
  DISPO_LIST: 'DISPO_LIST',
  ORDER_LIST: 'ORDER_LIST',
  PREVIEW: 'PREVIEW',
  PREVIEW_LIST: 'PREVIEW_LIST',
  PRODUCT_LIST: 'PRODUCT_LIST',
  SPECIAL: 'SPECIAL',
};

export const PRODUCT_TYPES = {
  TIX_ONLY: 'tixOnly',
};

export const PRODUCTS_UPLOAD_TARGET = {
  PREVIEW: 'PREVIEW',
  PROMOTIONAL_PACKAGE: 'PROMOTIONAL_PACKAGE',
  SPECIAL: 'SPECIAL',
};

export const PUBLICATION_DATE = {
  CUSTOM: 'custom',
  PUBLISHED: 'published',
  UNPUBLISHED: 'notPublished',
};

export const PUBLISH_ACTION = {
  PUBLISH: 'PUBLISH',
  DRAFT: 'DRAFT',
};

export const PUBLISHER_ID_TYPE = {
  MVB_ID: '05',
};

export const PUBLISHERS_SALES_EXPECTATION_CODE = 36;

export const PUBLISHER_TYPES = {
  MAIN: '01',
  CO: '02',
  ORIGINAL: '04',
};

export const PUBLISHING_STATUS = {
  ACTIVE: '04',
  INACTIVE: '08',
};

// a currency can have either 0, 1 or 2 numbers after the decimal point/comma
export const REGEX_CURRENCY = /^\d+(\.\d{0,2})?$/;

// a password has the following criteria:
// * at least 12 chars long
// * at least one lowercase letter
// * at least one uppercase letter
// * at least one number or one additional char
export const REGEX_PASSWORD = /^(?=.{12,}$)(?=.*[a-z])(?=.*[A-Z])((?=.*\d)|(?=.*\W))/;

// a phone number has the following criteria:
// * has numbers
// * has white spaces
// * has the chars / or -
export const REGEX_PHONE = /^\+?[\d\s/-]+$/;

export const REGEX_VIDEO =
  /(^(http(s)?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$)|(http(s)?:\/\/vimeo.com\/\d+(?=\b|\/))/;

export const RETAIL_SALE_TYPES = {
  PROMOTIONAL_PACKAGE: 'XY30',
  SPECIAL: 'XY11',
};

export const ROUTES_WITH_PACKED_QUERY_PARAMS = [
  'companies.index',
  'notes-overview.index',
  'order.dispo-lists.index',
  'order.dispo-lists.dispo-list.index',
  'order.order-history.index',
  'order.order-list.index',
  'party.companies',
  'party.customer-contacts.index',
  'party.groups.index',
  'party.groups.group',
  'party.users.index',
  'presentations.preview',
  'presentations.product-list',
  'presentations.dispo-list',
  'preview-lists.index',
  'preview-lists.preview-list.index',
  'previews.index',
  'previews.preview.index',
  'product-lists.index',
  'product-lists.product-list.index',
  'products.index',
  'specials.index',
];

export const SALES_RIGHTS_TYPES = {
  EXCLUSIVE: '01',
  NOT_EXCLUSIVE: '02',
  WITHOUT: '03',
};

export const SEARCH_MODE = {
  QUICK: 'QUICK',
  BATCH: 'BATCH',
  BATCH_ARCH: 'BATCH_ARCH',
  FIXED_BATCH: 'FIXED_BATCH',
  FIXED_BATCH_ARCH: 'FIXED_BATCH_ARCH',
  ADVANCED: 'ADVANCED',
  BOOLE: 'BOOLE',
};

export const WAWI_BOX_ERROR_CODES = {
  PRODUCT_NOT_FOUND: 'PRODUCT_NOT_FOUND',
  SERVER_ERROR: 'SERVER_ERROR',
};

export const PRODUCT_FILTER_TO_IGNORE = {
  PRODUCT_FILTER_UNASSIGNED_PUBLISHER: 'unassignedPublisher',
  PRODUCT_FILTER_NON_PREMIUM: 'nonPremium',
  PRODUCT_FILTER_IS_INACTIVE: 'isInactive',
  PRODUCT_FILTER_IS_SPECIAL: 'isSpecial',
  PRODUCT_FILTER_IS_DRAFT: 'isDraft',
};

// special values for tests are added last, so that 20 (first element) is default value in all environments
export const SEARCH_PAGE_SIZES = config.environment === 'test' ? [20, 50, 100, 10, 1] : [20, 50, 100];

export const INVALID_USERNAME = 'Invalid User';
export const INVALID_PASSWORD = 'invalidPassword';

export const SEARCH_RESOURCE_TYPE = {
  PRODUCT: 'search-product',
  PREVIEW: 'search-preview',
};

export const SECTION_CONTENT_TYPES = {
  TIX: 'TIX',
  VLB: 'VLB',
};

export const SECTION_TYPES = {
  ADVERTISING: 'ADVERTISING', // Werbemittel
  BIBLIOGRAPHY: 'BIBLIOGRAPHY', // Erweiterte Bibliografie
  CONTAINED_PRICES: 'CONTAINED_PRICES', // Preise für Aktionspakete
  CONTAINED_TITLES: 'CONTAINED_TITLES', //Enthaltete Titel von Aktionspaket
  CONTRIBUTOR: 'CONTRIBUTOR', // Urheber*innen
  DATE: 'DATE', //Termine und Lieferstatus
  EVENT: 'EVENT', // Events
  EXTENT: 'EXTENT', // Zusatzmaterial
  GALLERY: 'GALLERY', // Galerie
  HIGHLIGHTS: 'HIGHLIGHTS', // Hervorhebungen
  IMAGE_TEXT: 'IMAGE_TEXT', // Bild & Text
  INFO: 'INFO', // Cover & Inhalt
  KEYWORDS: 'KEYWORDS', // Schlagwörter
  MARKETING: 'MARKETING', // Marketing
  PACKAGE_SIZES: 'PACKAGE_SIZES', // Aktionspaket in anderen Größen
  PREVIEWS: 'PREVIEWS', // Vorschauen
  PROMOTION_PACKAGES: 'PROMOTION_PACKAGES', // Aktionspakete
  REFERENCETITLE: 'REFERENCETITLE', // Referenztitel
  REVIEW: 'REVIEW', // Rezensionen
  SALES_NOTES: 'SALES_NOTES', // Verkaufshinweise
  STAGE: 'STAGE', // Bühne
  VIDEO: 'VIDEO', // Videos
};

export const SECTION_STATUS = {
  PUBLISHED: 'PUBLISHED',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
};

export const WEBSHOP_LINK_EXAMPLE_IDENTIFIER = '9783765733024';

export const WEBSHOP_LINK_PLACEHOLDER_REGEX = {
  EAN: '\\[Test-EAN\\]',
  NAME: '\\[Webshop-Name\\]',
  STORE_ID: '\\[storeID\\]',
};

export const WEBSHOP_LINK_STRUCTURE = {
  EUROSOFT: 'https://[Webshop-Name].de/search?search=[Test-EAN]',
  GENIALOKAL: 'https://www.genialokal.de/affiliates/vlbtix?produkt=[Test-EAN]&storeID=[storeID]',
  LIBRI_BIZ: 'https://[Webshop-Name].biz/shop/articlebyEAN/[Test-EAN]',
  LIBRI: 'https://[Webshop-Name].de/shop/articlebyEAN/[Test-EAN]',
  SOFT_LEVEL: 'https://[Webshop-Name].de/shop/item/[Test-EAN]',
  UMBREIT: 'https://[storeID].umbreitshopsolution.de/shop/item/[Test-EAN]',
  ZEITFRACHT: 'https://[Webshop-Name].buchkatalog.de/[Test-EAN]',
  OTHER: 'https://[Webshop-Name][Test-EAN]',
};

export const WEBSHOP_LINK_TYPES = {
  EUROSOFT: 'EUROSOFT',
  GENIALOKAL: 'GENIALOKAL',
  LIBRI_BIZ: 'LIBRI_BIZ',
  LIBRI: 'LIBRI',
  SOFT_LEVEL: 'SOFT_LEVEL',
  UMBREIT: 'UMBREIT',
  ZEITFRACHT: 'ZEITFRACHT',
  OTHER: 'OTHER',
};

export const WEBSHOP_LINK_TYPES_WITH_STORE_ID = [WEBSHOP_LINK_TYPES.GENIALOKAL, WEBSHOP_LINK_TYPES.UMBREIT];

export const SSO_TOKEN_SCOPES = {
  SELF: 'SELF',
  IMPERSONATE: 'IMPERSONATE',
};

export const STATE_MACHINE_VALUES = {
  INITIAL: {
    UNPUBLISHED: 'unpublished',
    PUBLISHED: 'published',
  },
};

export const SUGGEST_CMC_TYPES = {
  DISPOLISTNOTE: 'DISPOLISTNOTE',
  MARKETINGEVENT: 'MARKETINGEVENT',
  REGION: 'REGION',
};

export const SWAP_BRANCH_MODAL_RESULTS = {
  DELETE: 'delete',
  TRANSFER: 'transfer',
};

export const SWAP_BRANCH_MODAL_TYPES = {
  INTERMEDIATE: 'intermediate',
  LAST: 'last',
};

export const TABLE_SORT_ORDERS = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
  DEFAULT: '',
};

// targetType for upload of file
export const FILE_UPLOAD_TARGET_TYPE = {
  CUSTOM_MARKETING_CONTENT: 'CUSTOM_MARKETING_CONTENT',
  PREVIEW: 'PREVIEW',
  PRODUCT: 'PRODUCT',
};

// from codelist 154
export const TEXT_CONTENT_AUDIENCES = {
  ALL: '00',
  RESTRICTED: '01',
  PUBLISHER: '02',
  SALES_COOP: '02',
  BOOK_GROUP: '02',
  BOOKSTORE: '02',
  READER: '03',
  JOURNALIST: '07',
  BLOGGER: '10',
};

// from codelist 153
export const TEXT_CONTENT_TYPE_CODES = {
  MAIN: '03',
  REVIEW: '06',
  CLAIM: '10',
  SALES_NOTES: '11',
  MATERIAL: '32',
};

export const TEXT_FORMATS = {
  XHTML: '05',
  PLAINTEXT: '06',
};

export const THALIA_AT_MVB_ID = '5282152';
export const THALIA_CH_MVB_ID = '5114922';
export const THALIA_DE_MVB_ID = '5303016';

export const THALIA_GRID_COLUMNS = ['C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'GM'];
export const THALIA_GRID_ROWS = ['ZD', 'ND'];

export const THALIA_MVB_ID_CATEGORIES = '5303016';
export const THALIA_MVB_IDS = [THALIA_AT_MVB_ID, THALIA_CH_MVB_ID, THALIA_DE_MVB_ID];

export const TITLE_TYPES = {
  MAIN: '01',
  ORIGINAL: '03',
  SHORT: '05',
  DISTRIBUTOR: '10',
};

export const TIX_LIST_TYPE = {
  DISPO_LIST: 'DISPO_LIST',
  ORDER_LIST: 'ORDER_LIST',
  PREVIEW_LIST: 'PREVIEW_LIST',
  PRODUCT_LIST: 'PRODUCT_LIST',
};

export const UNPRICED_ITEM_CODES_NOT_ORDERABLE = ['03', '05', '06', '08'];

export const URL_ASSIGNED_PARTIES = '/assigned-parties';
export const URL_BOOKGROUP_PURCHASE_RECOMMENDATION_DOWNLOAD = '/purchase-recommendations/download/bookgroup/';
export const URL_BOOKGROUP_PURCHASE_RECOMMENDATION_UPLOAD = '/purchase-recommendations/upload-async/bookgroup';
export const URL_CHANGE_PASSWORD = '/auth/change-password';
export const URL_CMCS_LOCKED = '/custom-marketing-contents/locked';
export const URL_COG_DELETE = '/category-of-goods/delete';
export const URL_COG_DOWNLOAD = '/category-of-goods/download';
export const URL_COG_UPLOAD = '/category-of-goods/upload';
export const URL_DISPO_LIST_EXPORT_FOXXY = '/dispo-lists/export-foxxy';
export const URL_DISPOLISTS_IMPORT_THALIA_QUANTITIES = '/dispo-lists/import-thalia-quantities';
export const URL_ERP_REFERENCED_PRODUCTS = '/erp-referenced-products/{productId}';
export const URL_FILE_UPLOAD = '/files/save';
export const URL_IMPORT_THALIA_REFERENCE_PRODUCTS = '/previews/thalia-import-comparative-products';
export const URL_ISBN_EXCEL_UPLOAD = '/search/isbn/excel';
export const URL_PREVIEW_HISTORY = '/preview/{ID}/receivers-export';
export const URL_PREVIEW_PUBLISH = '/previews/{ID}/publish';
export const URL_PREVIEW_UNPUBLISH = '/previews/{ID}/unpublish';
export const URL_PREVIEW_ORPHANED_DELETE = '/api/previews/orphaned';
export const URL_PRODUCT_EXPORT = '/products/export';
export const URL_PRODUCT_EXPORT_PREVIEW_HUGENDUBEL = '/products/export/hug/';
export const URL_PRODUCT_EXPORT_REFERENCE_PRODUCTS = '/products/export/reference-products';
export const URL_PRODUCT_EXPORT_THALIA = '/products/export/thalia';
export const URL_PRODUCT_PUBLISH = '/products/{ID}/publish';
export const URL_PROMOTIONAL_PACKAGE_CALCULATE_PRICE = '/products/promotional-package/calculate-price';
export const URL_PROMOTIONAL_PACKAGE_DELETE = '/products/{ID}';
export const URL_PROMOTIONAL_PACKAGE_SAVE = '/products/promotional-package';
export const URL_PURCHASE_RECOMMENDATIONS_UPLOAD = '/purchase-recommendations/upload';
export const URL_RESET_PASSWORD = '/auth/reset-password';
export const URL_SEARCH_AND_VALIDATE_PRODUCTS = '/search-and-validate-products';
export const URL_SET_PASSWORD = '/auth/set-password';
export const URL_SPECIAL_DELETE = '/products/{ID}';
export const URL_SPECIAL_SAVE = '/products/special';
export const URL_SUGGEST_CMC = '/suggest/custom-marketing-content/{type}';
export const URL_TIX = 'https://www.vlbtix.de';
export const URL_TIX_LOGIN = 'https://www.vlbtix.de/app/login';
export const URL_TIX_REGISTER = 'https://www.vlbtix.de/registrierung';
export const URL_TIX_TOS = 'https://www.vlbtix.de/link-pop-up-intern';
export const URL_TIX_AGB = 'https://www.vlbtix.de/agb';
export const URL_TIX_CONDITION_OF_USE = 'https://www.vlbtix.de/nutzungsbedingungen';
export const URL_TIX_ORDER_PROCESSING = 'https://www.vlbtix.de/auftragsverarbeitung';
export const URL_TIX_PRIVACY_INFORMATION = 'https://www.vlbtix.de/datenschutz';
export const URL_USERS_EXPORT = '/users/export';
export const URL_USER_REGISTER = '/user/register';
export const URL_USER_TOGGLE_HIGHLIGHTS_BHG = '/user/mayToggleHighlightsBookGroups';
export const URL_VALIDATE_EAN = '/validate/isbn';
export const URL_VALIDATE_PROMOTIONAL_PACKAGE_IDENTIFIER = '/products/promotional-package/validate';
export const URL_VIDEO_VIMEO = 'https://player.vimeo.com/video/';
export const URL_VIDEO_YOUTUBE = 'https://www.youtube-nocookie.com/embed/';

export const USER_COUNTRIES_DACH = {
  DE: 'DE',
  AT: 'AT',
  CH: 'CH',
};

export const USER_GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  NOT_STATED: 'NOT_STATED',
};

export const USER_GENRE = {
  AUDIO: 'AUDIO',
  CHILDREN: 'CHILDREN',
  FICTION: 'FICTION',
  GUIDES: 'GUIDES',
  ILLUSTRATED: 'ILLUSTRATED',
  NON_FICTION: 'NON_FICTION',
  TEXTBOOK: 'TEXTBOOK',
};

export const USER_PLATFORMS = {
  BLOG: 'BLOG',
  FACEBOOK: 'FACEBOOK',
  GOODREADS: 'GOODREADS',
  INSTAGRAM: 'INSTAGRAM',
  LOVELYBOOKS: 'LOVELYBOOKS',
  OTHERS: 'OTHERS',
  READ_O: 'READ_O',
  TIK_TOK: 'TIK_TOK',
  TWITTER: 'TWITTER',
  YOUTUBE: 'YOUTUBE',
};

export const USER_ROLES = {
  TIX_BO: 'TIX_BO',
  TIX_USER: 'TIX_USER',
};

export const USER_STATUS = {
  INVITED: 'INVITED',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED', // active state
  DISABLED: 'DISABLED',
};

export const USER_TITLE = {
  DR: 'DR',
  PROF: 'PROF',
  PROF_DR: 'PROF_DR',
};

export const USER_TYPE = {
  BLOGGER: 'BLOGGER',
  BOOK_GROUP: 'BOOK_GROUP',
  BOOKSTORE: 'BOOKSTORE',
  JOURNALIST: 'JOURNALIST',
  PUBLISHER: 'PUBLISHER',
  READER: 'READER',
  SALES_COOP: 'SALES_COOP',
};

export const UUID_LENGTH = 32;

export const WEB2PRINT_TEMPLATE = {
  BACKLIST: '06',
  CONTRIBUTORS: '04',
  INTERIOR: '03',
  MARKETING: '02',
  PRODUCTS2: '08',
  PRODUCTS3: '09',
  PRODUCTS4: '10',
  PROMO: '07',
  SPECIAL: '05',
  STANDARD: '01',
};

/**
 * constants that need to be at the end because of initialization order
 */
export const HIGHLIGHT_AUDIENCES = {
  [PARTY_TYPE.BOOK_GROUP]: 'B1',
  [PARTY_TYPE.BOOKSTORE]: '02',
  [PARTY_TYPE.PUBLISHER]: 'V1',
  [PARTY_TYPE.SALES_COOP]: 'V2',
  [USER_TYPE.BLOGGER]: '10',
  [USER_TYPE.JOURNALIST]: '07',
  [USER_TYPE.READER]: '03',
  ALL: '00',
};

export const HIGHLIGHT_AUDIENCES_ORDERED = [
  HIGHLIGHT_AUDIENCES.ALL,
  HIGHLIGHT_AUDIENCES[USER_TYPE.BLOGGER],
  HIGHLIGHT_AUDIENCES[PARTY_TYPE.BOOKSTORE],
  HIGHLIGHT_AUDIENCES[USER_TYPE.JOURNALIST],
  HIGHLIGHT_AUDIENCES[USER_TYPE.READER],
  HIGHLIGHT_AUDIENCES[PARTY_TYPE.PUBLISHER],
  HIGHLIGHT_AUDIENCES[PARTY_TYPE.SALES_COOP],
  HIGHLIGHT_AUDIENCES[PARTY_TYPE.BOOK_GROUP],
];

/**
 * constants that are only used for testing or mirage
 */

// from codelist 17
export const CONTRIBUTOR_ROLE_CODES = {
  AUTHOR: 'A01',
  AUTHOR_OF_FOREWORD: 'A23',
  EDITOR: 'B01',
  ILLUSTRATOR: 'A12',
  TRANSLATOR: 'B06',
};

export const MEDIA_INDEX = {
  TASCHENBUCH: '12',
};

export const PUBLISHER_TEST = {
  MVBID: '89499',
  NAME: 'Testverlag',
  EMAIL: 'vertrieb@mvb-online.de',
  FAX: '+49 (069) 1306-255',
  TELEPHONE: '+49 (069) 1306-550',
  URL: 'www.mvb-online.de',
  PUBLISHERID: '10',
};
