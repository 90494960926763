import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showFilter}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{this.onReset}}\n    {{did-update this.onValueArgumentChange @value}}\n    data-test-sent-previews-filter\n  >\n    <:content>\n      {{#if this.linkExpired}}\n        {{t \"searchFilterSentPreviews.text.linkExpired\"}}\n      {{else}}\n        {{t \"searchFilterSentPreviews.text.active\" date=this.created}}\n      {{/if}}\n    </:content>\n  </Search::Filter>\n{{/if}}", {"contents":"{{#if this.showFilter}}\n  <Search::Filter\n    @filter={{@filter}}\n    @notCollapsible={{true}}\n    @onReset={{this.onReset}}\n    {{did-update this.onValueArgumentChange @value}}\n    data-test-sent-previews-filter\n  >\n    <:content>\n      {{#if this.linkExpired}}\n        {{t \"searchFilterSentPreviews.text.linkExpired\"}}\n      {{else}}\n        {{t \"searchFilterSentPreviews.text.active\" date=this.created}}\n      {{/if}}\n    </:content>\n  </Search::Filter>\n{{/if}}","moduleName":"@mvb/tix-ui/components/search/filter/sent-previews/index.hbs","parseOptions":{"srcName":"@mvb/tix-ui/components/search/filter/sent-previews/index.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitFor } from '@ember/test-waiters';
import ENV from '@mvb/tix-ui/config/environment';
import SearchFilterBaseComponent from '@mvb/tix-ui/components/search/filter/base';

export default class SearchFilterSentPreviewsComponent extends SearchFilterBaseComponent {
  @service store;
  @tracked created;
  @tracked linkExpired = false;

  get showFilter() {
    return this.args.value;
  }

  constructor() {
    super(...arguments);

    this.value = this.defaultValue ?? null;
    this.onValueArgumentChange();
    this.fetchCreatedDate.perform();
  }
  @task({ drop: true })
  @waitFor
  *fetchCreatedDate() {
    try {
      let sentPreview = yield this.store.findRecord('sent-preview', this.args.value);
      this.created = new Date(sentPreview.created).toLocaleDateString(ENV.i18n.locale);
    } catch {
      this.linkExpired = true;
    }
  }

  @action
  onReset() {
    this.value = this.defaultValue ?? null;
    this.search(this.value);
  }
}
