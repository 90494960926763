define('@fortawesome/pro-duotone-svg-icons', ['exports'], (function (exports) { 'use strict';

  /*!
   * Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com
   * License - https://fontawesome.com/license (Commercial License)
   * Copyright 2024 Fonticons, Inc.
   */
  const faSort = {
    prefix: 'fad',
    iconName: 'sort',
    icon: [320, 512, ["unsorted"], "f0dc", ["M0 320c0 8.3 3.3 16.5 9.4 22.6l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 288c-12.9 0-24.6 7.8-29.6 19.8C.8 311.7 0 315.9 0 320z", "M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]]
  };

  exports.faSort = faSort;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
