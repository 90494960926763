export default {
  "info": "_info_zrc18",
  "cover": "_cover_zrc18",
  "content": "_content_zrc18",
  "description-list-view": "_description-list-view_zrc18",
  "description-wrapper": "_description-wrapper_zrc18",
  "placeholder": "_placeholder_zrc18",
  "spaced": "_spaced_zrc18",
  "border": "_border_zrc18",
  "products-wrapper": "_products-wrapper_zrc18",
  "content-header": "_content-header_zrc18"
};
