import { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { USER_ROLES, USER_STATUS, USER_TYPE } from '@mvb/tix-ui/constants';
import Model from './-base';

export default class UserModel extends Model {
  @service intl;

  @attr('date') created;
  @attr('date') expiration;
  @attr('date-time') termsAcceptedAt;
  @attr('date-time') lastActivity;
  @attr('number') assignedCount;
  @attr('string') about;
  @attr('string') currency;
  @attr('string') currencyCountry;
  @attr('string') fax;
  @attr('string') firstname;
  @attr('string') gender;
  @attr('string') lastname;
  @attr('string') mobile;
  @attr('string') phone;
  @attr('string') status;
  @attr('string') title;
  @attr('string') type;
  @attr('string') username;
  @attr() clients;
  @attr({ defaultValue: () => [] }) genres;
  @attr() roles;

  @belongsTo('address', { async: false, inverse: null }) address;
  @belongsTo('blog-info', { async: false, inverse: 'user' }) blogInfo;
  @belongsTo('file', { async: false, inverse: null }) avatar;
  @belongsTo('preview-receiver', { async: false, inverse: 'recipient' }) recipient;
  @belongsTo('preview-receiver', { async: false, inverse: 'sender' }) sender;
  @belongsTo('registration-input', { async: true, inverse: 'user' }) registrationInput;
  @belongsTo('user-setting', { async: false, inverse: null }) userSettings;

  @hasMany('assigned-party', { async: false, inverse: 'user' }) assignedParties;
  @hasMany('customer-contact', { async: false, inverse: 'user' }) customerContacts;
  @hasMany('group', { async: false, inverse: 'members' }) groups;

  get countryLabel() {
    return this.address.countryLabel;
  }

  get fullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  get fullNameInverse() {
    return `${this.lastname}, ${this.firstname}`;
  }

  get genresTextSorted() {
    return this.genres
      ?.map((genre) => this.intl.t(`modelUser.text.genres.${genre}`))
      .sort((a, b) => a.localeCompare(b));
  }

  // should only be used for registration and profile
  get canHaveAssignedParties() {
    return this.isBookGroup || this.isBookstore || this.isPublisher || this.isSalesCoop;
  }

  get hasAssignedParties() {
    return this.hasMany('assignedParties').ids().length > 0;
  }

  get isActive() {
    return this.status === USER_STATUS.CONFIRMED;
  }

  get isBackofficeUser() {
    return this.roles?.includes(USER_ROLES.TIX_BO);
  }

  get isBlogger() {
    return this.type === USER_TYPE.BLOGGER;
  }

  // should only be used for registration and profile
  get isBookGroup() {
    return this.type === USER_TYPE.BOOK_GROUP;
  }

  // should only be used for registration and profile
  get isBookstore() {
    return this.type === USER_TYPE.BOOKSTORE;
  }

  get isCorporateUser() {
    return [USER_TYPE.BOOK_GROUP, USER_TYPE.BOOKSTORE, USER_TYPE.PUBLISHER, USER_TYPE.SALES_COOP].includes(this.type);
  }

  get isJournalist() {
    return this.type === USER_TYPE.JOURNALIST;
  }

  // should only be used for registration and profile
  get isPublisher() {
    return this.type === USER_TYPE.PUBLISHER;
  }

  get isReader() {
    return this.type === USER_TYPE.READER;
  }

  // should only be used for registration and profile
  get isSalesCoop() {
    return this.type === USER_TYPE.SALES_COOP;
  }

  get partyPermissionsLabel() {
    if (isEmpty(this.partyPermissionSetLabels)) {
      return '';
    }

    if (this.partyPermissionSetLabels.size === 1) {
      let [permissionSetLabel] = this.partyPermissionSetLabels;
      return permissionSetLabel;
    }

    return this.intl.t(`modelUser.text.partyPermissions.multiple`);
  }

  get partyPermissionSetLabels() {
    return new Set(this.assignedParties.map((assignedParty) => assignedParty.permissionSet.label));
  }

  get roleLabel() {
    return this.roles?.length ? this.roles.map((r) => this.intl.t(`modelUser.text.role.${r}`)).join(', ') : '';
  }

  get salutation() {
    return this.gender ? this.intl.t(`modelUser.text.salutation.${this.gender.toLowerCase()}`) : '';
  }

  get statusLabel() {
    return this.intl.t(`modelUser.text.status.${this.status}`);
  }

  get typeLabel() {
    return this.intl.t(`modelUser.text.type.${this.type}`);
  }

  get fullNameWithEmail() {
    return `${this.fullName.trimEnd()} <${this.username}>`;
  }
}
